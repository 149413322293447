import React, { useContext } from 'react';
import { MicrocopyContext } from '../context/microcopy.context';
import { getCopy, withMicrocopy } from '../helpers/copy';
import Container from '../modules/container';
import Link from '../components/ui/link';
import Logo from '../images/logo.svg';
import Text from '../components/ui/text';
import * as styles from '../styles/404.module.scss';
import { combine } from '../helpers/styles';
import { useLocale } from '../context/locale.context';

const UnderMaintenance = () => {
  const { locale } = useLocale();
  const TranslatedContent = withMicrocopy(() => {
    const microcopy = useContext(MicrocopyContext);
    return (
      <main className={styles.pageStyles}>
        <Container>
          <div className={styles.header}>
            <Link link={{ linkToPage: '/' }}>
              <Logo className={styles.logo} />
            </Link>
          </div>
          <div className={styles.contentContainer}>
            <Text
              as="h1"
              type="h3"
              className={combine(styles.white, styles.headline)}
            >
              {getCopy('label.underMaintenance', microcopy)}
            </Text>
            <Text
              as="span"
              type="h3"
              className={combine(styles.white, styles.text)}
            >
              {getCopy('label.underMaintenanceText', microcopy)}
            </Text>
          </div>
        </Container>
      </main>
    );
  }, locale);
  return <TranslatedContent />;
};

export default UnderMaintenance;
